import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import beacon from '@ui/axios/searchSpring/beacon';
import { LeftChevronIcon, RightChevronIcon } from '@ui/components/core';
import ProductCard, { ProductCardProps } from '../../cards/ProductCard/ProductCard';
import cn from '@ui/utils/cn';
import { SwiperCarousel, SwiperRef, SwiperSlide } from '@ui/components/core/carousel';
import { SwiperOptions } from 'swiper/types';
import { Button } from '@ui/components/core/button';

type ProductCardCarouselProps = {
  collection?: string;
  carousel: { tag: string; placement: string; products: Array<any> };
  height: number;
  width: number;
  loop?: boolean;
  className?: string;
  withControls?: boolean;
  layout?: ProductCardProps['layout'];
  swiperOptions?: SwiperOptions;
};

const ProductCardCarousel = ({
  collection,
  carousel,
  height,
  width,
  loop,
  className,
  withControls = false,
  layout,
  swiperOptions,
}: ProductCardCarouselProps): React.ReactElement => {
  const [carouselViewEventFired, setCarouselEventFired] = React.useState<boolean>(false);
  const [inViewProducts, setInViewProducts] = React.useState<Array<string>>([]);
  const [viewedProducts, setViewedProducts] = React.useState<string[]>([]);
  const addViewedProducts = React.useCallback(
    (product: string) => {
      if (!viewedProducts.includes(product)) {
        setViewedProducts((prevProducts) => [...prevProducts, product]);
      }
    },
    [viewedProducts],
  );

  const [ssBeacon, setSSBeacon] = React.useState<
    | {
        id: string;
        tag: string;
        placement: string;
      }
    | undefined
  >(undefined);

  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const ref = useRef<SwiperRef>(null);
  const prev = useRef<HTMLButtonElement>(null);
  const next = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (carousel.tag && isIntersecting && !ssBeacon) {
      const id = uuidv4();

      setSSBeacon({
        id,
        tag: carousel.tag,
        placement: carousel.placement,
      });
    }
  }, [ssBeacon, carousel, isIntersecting]);

  useEffect(() => {
    if (!carouselViewEventFired && isIntersecting && ssBeacon && inViewProducts.length > 0) {
      beacon({
        type: 'profile.render',
        id: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
      }).then(() => {
        beacon({
          type: 'profile.impression',
          id: ssBeacon.id,
          tag: ssBeacon.tag,
          placement: ssBeacon.placement,
        });
      });

      beacon({
        type: 'profile.product.render',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        skus: inViewProducts,
      }).then(() => {
        beacon({
          type: 'profile.product.impression',
          id: uuidv4(),
          pid: ssBeacon.id,
          tag: ssBeacon.tag,
          placement: ssBeacon.placement,
          skus: inViewProducts,
        });
      });

      setCarouselEventFired(true);
    }
  }, [carouselViewEventFired, inViewProducts, isIntersecting, ssBeacon]);

  useEffect(() => {
    if (!isIntersecting) {
      setCarouselEventFired(false);
      setInViewProducts([]);
    }
  }, [isIntersecting]);

  return (
    <div className={cn('flex relative',className)}>
      <SwiperCarousel parentRef={ref} 
        buttons={{ prev, next }}
        loop={loop}
        {...swiperOptions}
      >
        {carousel.products.map((product) => (
          <SwiperSlide key={product.handle} data-carousel-item={`carousel-item-${product.handle}`}>
            <div>
            <ProductCard
              draggable={false}
              addViewedProducts={addViewedProducts}
              ssBeacon={ssBeacon}
              collection={collection}
              product={product}
              href={
                collection ? `/collections/${collection}/products/${product.handle}/` : `/products/${product.handle}/`
              }
              height={height}
              width={width}
              layout={layout}
            />
            </div>
          </SwiperSlide>
        ))}

      </SwiperCarousel>
      <div className={cn('absolute top-[40%] -translate-y-1/2 -left-[35px] hidden justify-between items-center pointer-events-none z-[10]',withControls ? "md:flex":"")} style={{width: 'calc(100% + 70px)'}}>
        <Button ref={prev} variant='unstyled' 
          className='p-2 mr-4 pointer-events-auto transition duration-200 opacity-100 disabled:opacity-[0%] h-[300px]'
        >
          <LeftChevronIcon height={20} width={20} />
        </Button>
        <Button ref={next} variant='unstyled' 
          className='p-2 ml-4 pointer-events-auto transition duration-200 opacity-100 disabled:opacity-[0%] h-[300px]'
        >
          <RightChevronIcon height={20} width={20} />
        </Button>
      </div>
    </div>
  );
};

ProductCardCarousel.defaultProps = {
  collection: undefined,
  loop: false,
  className: undefined,
  withControls: false,
};

export default ProductCardCarousel;
